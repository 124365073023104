import React from 'react';
import { FaRocket, FaServer, FaBriefcase } from 'react-icons/fa'; // Import icons from Font Awesome
import './ServiceCards.css';
import { motion } from 'framer-motion';
// Service Card Component
const ServiceCard = ({ title, Icon, description, contactText }) => {
    return (
        <motion.div
            className="container"
            initial={{ opacity: 0, y: 50 }} // Initial animation state
            whileInView={{ opacity: 1, y: 0 }} // Final animation state when in view
            transition={{ duration: 0.6 }} // Animation duration
            viewport={{ once: true }} // Trigger animation only once
        >
            <div className="service-card">
                <div className="ico">
                    <div className="service-icon"><Icon size={50} /></div>
                </div>
                <div className="headdes">
                    <h5 className="service-title">{title}</h5>
                    <p>{description}</p>
                </div>
            </div>
        </motion.div>
    );
};

// Main Component Exporting All Service Cards
const ServiceCards = () => {
    return (
        <>
            <div className="container services">
                <ServiceCard
                    title="Software Solutions"
                    Icon={FaRocket}
                    description="Custom web and app development solutions."
                />
                <ServiceCard
                    title="Hardware Solutions"
                    Icon={FaServer}
                    description="Reliable hardware supplies and networking solutions."
                />
                <ServiceCard
                    title="Internship Opportunities"
                    Icon={FaBriefcase}
                    description="Hands-on internships for future tech professionals."
                />
            </div>

      
      
        </>
    );
};

export default ServiceCards;
