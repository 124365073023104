import {React, useEffect} from "react"; 
import './FirstPage.css'
import Navbar from "../../components/Navbar/Navbar";
import Coursel from '../../pages/Home/Coursel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhone, faEnvelope, faBars } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';


const FirstPage = () => {
 
    return (
        <>
        <div className="topbar">
            <div className="container">
                <div className="top-bar1">
                    <div className="ics">
                        <span><FontAwesomeIcon icon={faMapMarkerAlt} style={{ color: '#fff' }} /> AIC Incubation center, NITTE</span>
                       
                    </div>
                        <div className="social-icons">
                            <span><FontAwesomeIcon icon={faPhone} style={{ color: '#fff' }} /> +91 9108316049</span>
                            <span><FontAwesomeIcon icon={faEnvelope} style={{ color: '#fff' }} /> neuraltechitsolution@gmail.com</span>
                        </div>
                </div>
            </div>
            </div> 



            
            <div className="navbarcont fulpage">
                <Navbar />
             
</div>
         

         

        </>
    );
};

export default FirstPage;
