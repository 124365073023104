import React from "react";
import {
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import teamImage from "../../assets/images/logo.png";
import achievementImage from "../../assets/images/logo.png";
import "./AboutUs.css";
import './Review.css';
import { Link } from 'react-router-dom';
import pageHome from '../pageHome/pageHome';
import sampleImage3 from '../../assets/images/sampleimage3.jpg'
const AboutUs = () => {
  return (
    <>
      <div className="side-by-sideabt-containers">
        <div className="container sidebysideabt">
          <div className="left-boxesabt">
            {/* <h5 className='fpabt'>We provide professional IT services</h5> */}
            <h1 className="headingabt">Exclusive technology to provide IT solutions</h1>
            <h5 className="h5des">Neuraltech IT Solutions offers tailored IT services, including app development and hardware supplies ensuring innovation and reliability</h5>
            <p className="descriptionabt">
              Neuraltech IT Solutions delivers exclusive, customized IT services — from client-based app and web development to hardware supplies and networking solutions — ensuring innovation and reliability for your business needs.
            </p>
            <Link to="/aboutus">
              <button className="action-buttonabt">More About Us</button>
            </Link>
          </div>
          <div className="right-boxesabt">
            <img src={sampleImage3} alt="Sample" className="imageabt" />
          </div>
        </div>
      </div>
       
    </>
  );
};

export default AboutUs;
