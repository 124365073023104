import React from 'react';
import './ProjectCards.css';
import sampleimage from '../../assets/images/sampleImage.jpg';
import sampleimage2 from '../../assets/images/sampleImage2.jpg';
import { FiExternalLink } from 'react-icons/fi'; // Importing the desired icon

const projects = [
    {
        title: 'Project for Marketing',
        category: 'Business, Marketing',
        imageSrc: sampleimage2,
    },
    {
        title: 'Marketing Agency Website',
        category: 'Development, Marketing',
        imageSrc: sampleimage,
    },
    {
        title: 'Health App Development',
        category: 'Development, Software',
        imageSrc: sampleimage,
    },
    {
        title: 'Cyber Security Analysis',
        category: 'Security, Technology',
        imageSrc: sampleimage,
    }, 
    {
        title: 'Digital Product Design',
        category: 'Design, Graphics',
        imageSrc: sampleimage,
    },
    {
        title: 'Digital Product Design',
        category: 'Design, Graphics',
        imageSrc: sampleimage,
    },
];

const ProjectCards = () => {
    return (
        <>
            <h2 className="procar">Explore our recent projects</h2>
        <div className="container">
        <div className="grid">
            {projects.map((project, index) => (
                <div key={index} className="card">
                    <div className="imagese-container">
                        <img src={project.imageSrc} alt={project.title} className="imagese" />
                        <div className="vignette"></div>
                        <div className="icon-overlay">
                            <FiExternalLink />
                        </div>
                        <div className="text-content">
                            <h3 className="title">{project.title}</h3>
                            <p className="category">{project.category}</p>
                        </div>
                    </div>
                  
                </div>
               
                        
                 
            ))}
           
          </div>    
        </div>
            <div className="bt">
                <button className="action-button" style={{ textAlign: 'center' }}>View Our All Work</button>
            </div>
        </>
    );
};

export default ProjectCards;
