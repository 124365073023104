import React from 'react';
import './SideBySideBox.css';
import sampleImage from '../../assets/images/sampleImage.jpg'; // Replace with your image path

const SideBySideBox = () => {
    return (
     <>
        <div className="side-by-side-containers">
        <div className="container sidebyside">
            <div className="left-boxes">
                    <h5 className='fp'>We provide professional IT services</h5>
                    <h1 className="heading">Delivering tech solutions for your Buisness</h1>
                <p className="description">
                        We transform businesses of most major sectors with powerful and adaptable digital solutions that satisfy the needs of today.
                </p>
                <button className="action-button">Learn More</button>
                    <button className="action-button getq">Get Quote</button>
            </div>
            <div className="right-boxes">
                <img src={sampleImage} alt="Sample" className="image" />
            </div>
            </div>
        </div>
      
        </>
    );
};

export default SideBySideBox;
