import React from "react";
import "./TeamSection.css"; // Assuming you save the CSS file as TeamSection.css
import Avinash from '../../assets/images/Avinash.png';
import Rakshith from '../../assets/images/rakshith.jpg'
import Darshith from '../../assets/images/darshith.png'
import { faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const teamMembers = [
    {
        name: "Avinash",
        position: "Marketing Executive Manager",
        image: Avinash, // Replace with your local image
        socials: {
            twitter: "http://www.twitter.com/",
            facebook: "http://www.facebook.com/",
            instagram: "http://www.instagram.com/",
            email: "http://www.gmail.com/",
        },
    },
    {
        name: "Rakshith",
        position: "Software Development Manager",
        image: Rakshith, // Replace with your local image
        socials: {
            twitter: "http://www.twitter.com/",
            facebook: "http://www.facebook.com/",
            instagram: "http://www.instagram.com/rakshii.th_19",
            email: "mailto:rakshithrakshii26@gmail.com",
        },
    },
    {
        name: "Darshith",
        position: "Business Development Manager",
        image: Darshith, // Replace with your local image
        socials: {
            twitter: "http://www.twitter.com/",
            facebook: "http://www.facebook.com/",
            instagram: "http://www.instagram.com/",
            email: "http://www.gmail.com/",
        },
    },
 
];


const TeamCard = ({ name, position, image, socials }) => (
    <div className="responsive-cell-block wk-desk-3 wk-ipadp-3 wk-tab-6 wk-mobile-12 team-card-container">
        <div className="team-card">
            <div className="img-wrapper">
                <img className="team-img" src={image} alt={`${name}`} />
            </div>
            <div className="txtshe">
            <p className="text-blk name">{name}</p>
            <p className="text-blk position">{position}</p>
           
            <div className="social-media-links">
                    <a href={socials.twitter} target="_blank" rel="noreferrer" className="faiconsin">
                        <FontAwesomeIcon icon={faTwitter} size="lg" />
                    </a>

                    <a href={`mailto:${socials.email}`} target="_blank" rel="noreferrer" className="faiconsin" >
                        <FontAwesomeIcon icon={faEnvelope} size="lg" />
                    </a>

                    <a href={socials.linkedin} target="_blank" rel="noreferrer" className="faiconsin">
                        <FontAwesomeIcon icon={faLinkedin} size="lg" />
                    </a>

            </div>
            </div>
           
        </div>
    </div>
);

const TeamSection = () => (
    <>
        
    <div className="responsive-container-block outer-container">
            <div className="headteam">
                <h2 className="text-blk section-head-text ht">Meet Our Team</h2>
                <p className="text-blk section-subhead-text">
                    Behind The Creative Process Of Building Your Brand
                </p>
            </div>
        <div className="responsive-container-blocks inner-container">
           
            <div className="responsive-container-blocked">
                {teamMembers.map((member, index) => (
                    <TeamCard key={index} {...member} />
                ))}
            </div>
        </div>
    </div>
    </>
);

export default TeamSection;
