import React from "react";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import "./Service.css";
import Illustration1 from'../../../assets/images/illustration1.png';
import Illustration2 from '../../../assets/images/illustration2.png';
import Illustration3 from '../../../assets/images/illustration3.png';
import { motion } from 'framer-motion';
import { FaRocket, FaServer, FaBriefcase } from 'react-icons/fa';
import { FaShieldAlt, FaMobileAlt, FaChartBar, FaLock, FaTools, FaPalette } from 'react-icons/fa';

const ServiceCard = ({ title, Icon, description, contactText }) => {
    return (
        <motion.div
            className="container"
            initial={{ opacity: 0, y: 50 }} // Initial animation state
            whileInView={{ opacity: 1, y: 0 }} // Final animation state when in view
            transition={{ duration: 0.6 }} // Animation duration
            viewport={{ once: true }} // Trigger animation only once
        >
            <div className="service-card">
                <div className="ico">
                    <div className="service-icon"><Icon size={50} /></div>
                </div>
                <div className="headdes">
                    <h5 className="service-title">{title}</h5>
                    <p>{description}</p>
                </div>
            </div>
        </motion.div>
    );
};

const Service = () => {
    const breadcrumbs = [
        { label: "Home", link: "home" },
        { label: "Service", link: "service" },
    ];

    const services = [
        {
            id: 1,
            title: 'Data Tracking Security',
            description: 'Each demo built with Teba will look different. You can customize almost anything the appearance.',
            icon: <FaShieldAlt />,
            link: '#'
        },
        {
            id: 2,
            title: 'Web & Mobile App Development',
            description: 'Each demo built with Teba will look different. You can customize almost anything the appearance.',
            icon: <FaMobileAlt />,
            link: '#'
        },
        {
            id: 3,
            title: 'Digital Marketing Services',
            description: 'Each demo built with Teba will look different. You can customize almost anything the appearance.',
            icon: <FaChartBar />,
            link: '#'
        },
        {
            id: 4,
            title: 'Cyber Security Solutions',
            description: 'Each demo built with Teba will look different. You can customize almost anything the appearance.',
            icon: <FaLock />,
            link: '#'
        },
        {
            id: 5,
            title: 'IT Management Service',
            description: 'Each demo built with Teba will look different. You can customize almost anything the appearance.',
            icon: <FaTools />,
            link: '#'
        },
        {
            id: 6,
            title: 'UI/UX & Branding Identity',
            description: 'Each demo built with Teba will look different. You can customize almost anything the appearance.',
            icon: <FaPalette />,
            link: '#'
        }
    ];

    return (
        <>
            <Breadcrumb pageTitle="Service" breadcrumbs={breadcrumbs} />
            <div className="container services">
                <ServiceCard
                    title="Software Solutions"
                    Icon={FaRocket}
                    description="Custom web and app development solutions."
                />
                <ServiceCard
                    title="Hardware Solutions"
                    Icon={FaServer}
                    description="Reliable hardware supplies and networking solutions."
                />
                <ServiceCard
                    title="Internship Opportunities"
                    Icon={FaBriefcase}
                    description="Hands-on internships for future tech professionals."
                />
            </div>
            <div className="container">
                <section className="services-sections">
                    <h2 className="services-headings">Our awesome services to give you success</h2>
                    <div className="services-grids">
                        {services.map((service) => (
                            <div key={service.id} className="service-cards">
                            
                                <div className="icons">{service.icon}</div>
                                <div className="imsesr">
                                <h3 className="service-titles">{service.title}</h3>
                                <p className="service-descriptions">{service.description}</p>
                                <a href={service.link} className="learn-mores">Learn More &rarr;</a>
                            </div>
                            </div>
                        ))}
                    </div>
                </section>

            </div>


            <div className="hero-section">
                <motion.div
                    className="hero-content"
                    initial={{ opacity: 0, y: 100 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                    viewport={{ once: true }}
                >
                    <h2 className="hero-heading">Let's work together</h2>
                    <p className="hero-description">
                        Your partner for innovative app and web development, hardware solutions, and career opportunities.
                    </p>
                    <button className="hero-button">Let's Start a Project</button>
                </motion.div>
            </div>
            <div className="container serhe">
                <h2 className="section-title">
                    Our working process on how to grow your business
                </h2>
                {/* <hr className="linedot" /> */}
                <section className="services">
          
                    <div className="service-container mobvie">
                        <div className="service-step">
                            <img
                                src={Illustration1}
                                alt="Initiation & Planning Icon"
                            />
                 <h2 className="rou">01</h2>
                            <h3>Initiation & Planning</h3>
                            <p>We are architects, innovation trailblazers of technological advancement.</p>
                        </div>

                        <div className="service-step">
                            <img
                                src={Illustration2}
                                alt="Execution & Development Icon"
                            />
                            <h2 className="rou">02</h2>
                            <h3> Execution & Development</h3>
                            <p>We are architects, innovation trailblazers of technological advancement.</p>
                        </div>

                        <div className="service-step">
                            <img
                                src={Illustration3}
                                alt="Testing & Maintenance Icon"
                            />
                            <h2 className="rou">03</h2>
                            <h3 > Testing & Maintenance</h3>
                            <p>We are architects, innovation trailblazers of technological advancement.</p>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default Service;
