import React from "react";
import Coursel from './Coursel'
import Review from "../Review/Review";
import AboutUs from "../AboutUs/AboutUs";
import PricingTable from '../PricingTable/PricingTable'
import Team from '../TeamSection/TeamSection';
import pageHome from '../pageHome/pageHome'
import FirstPage from "../FirsPage.js/FirstPage";
import SideBySideBox from '../SideBySideBox/SideBySideBox';
import ServiceCards from '../ServiceCards/ServiceCards';
import ProjectCards from '../ProjectCards/ProjectCards';



const Home = () => {

    return (
        <>

            <SideBySideBox />
            <ServiceCards />
            <pageHome />
            <AboutUs />
            <PricingTable />
            <Team />
           
            <ProjectCards />
            <Review />

        </>
    );
};

export default Home;
