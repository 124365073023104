// src/App.js
import React from 'react';
import AppRoutes from './routes/AppRoutes';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import './styles/global.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import FirstPage from './pages/FirsPage.js/FirstPage';
function App() {
  return (
    <div className="App">
      <FirstPage />
      <AppRoutes />
      <Footer />
    </div>
  );
}

export default App;
