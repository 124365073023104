import React from 'react';
import Breadcrumb from '../Breadcrumb/Breadcrumb'; // Adjust path if needed
import './About.css'; // Include CSS for styling
import AboutUs from '../../../pages/AboutUs/AboutUs';
import ContactUs from '../ContactUs/ContactUs';
import { motion } from 'framer-motion';
import { FaChartLine, FaUsers, FaHeadset } from 'react-icons/fa'; // Import related icons
import groupImage1 from "../../../assets/images/sampleImage.jpg"; // Add appropriate images
import groupImage2 from "../../../assets/images/sampleImage.jpg";

const About = () => {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'About Us', link: '/about' },
    ];

    return (
        <>
            <div className="about-us-container">
                <Breadcrumb pageTitle="About Us" breadcrumbs={breadcrumbs} />
                <AboutUs />
                <div className="services-container container">
                    {/* Title Section */}
                    <h1 className="services-title">Providing IT solutions & services for startups</h1>

                    <div className="services-content">
                        {/* Features Section */}
                        <div className="features-section">
                            <div className="feature-item">
                                <FaChartLine className="feature-icon" />
                                <div className="feature-content">
                                    <h3>Quality Solution for Business</h3>
                                    <p>Each demo built with Teba will look different. Customize almost anything in the appearance of your site.</p>
                                </div>
                            </div>

                            <div className="feature-item">
                                <FaUsers className="feature-icon" />
                                <div className="feature-content">
                                    <h3>Amazing Expert Teams</h3>
                                    <p>Each demo built with Teba will look different. Customize almost anything in the appearance of your site.</p>
                                </div>
                            </div>

                            <div className="feature-item">
                                <FaHeadset className="feature-icon" />
                                <div className="feature-content">
                                    <h3>Urgent Support For Clients</h3>
                                    <p>Each demo built with Teba will look different. Customize almost anything in the appearance of your site.</p>
                                </div>
                            </div>
                        </div>

                        {/* Image Section */}
                        <div className="images-section">
                            <img src={groupImage1} alt="Team" className="main-image" />
                           
                        </div>
                    </div>
                </div>

                {/* Hero Section */}
                <div className="hero-section">
                    <motion.div
                        className="hero-content"
                        initial={{ opacity: 0, y: 100 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8 }}
                        viewport={{ once: true }}
                    >
                        <h2 className="hero-heading">Let's work together</h2>
                        <p className="hero-description">
                            Your partner for innovative app and web development, hardware solutions, and career opportunities.
                        </p>
                        <button className="hero-button">Let's Start a Project</button>
                    </motion.div>
                </div>

                {/* Contact Section */}
                <div className="contact-container container">
                    <div className="contact-info">
                        <h2 className="contact-heading">Let's build an awesome project together</h2>
                        <p className="contact-description">
                            We’re here to help bring your ideas to life. Whether you need a website, mobile app, or custom software solution, our team of experts is ready to collaborate and deliver exceptional results tailored to your needs.
                        </p>

                        <div className="contact-details">
                            <div className="contact-item">
                                <div className="icon-box">
                                    <span className="icon">&#128205;</span>
                                </div>
                                <div>
                                    <h4>Address</h4>
                                    <p>AIC Incubation center<br />NITTE, 574110</p>
                                </div>
                            </div>

                            <div className="contact-item">
                                <div className="icon-box">
                                    <span className="icon">&#9993;</span>
                                </div>
                                <div>
                                    <h4>Contact</h4>
                                    <p>neuraltechitsolution@gmail.com<br />+91 9108316049</p>
                                </div>
                            </div>
                        </div>

                        <p className="office-hours">Office Hours: Mon – Sat: 9:30 AM – 5:30 PM</p>
                    </div>

                    <div className="contact-form">
                        <h3>Fill The Contact Form</h3>
                        <p>Feel free to contact with us, we don’t spam your email</p>

                        <form>
                            <div className="form-group">
                                <input type="text" placeholder="Your name" />
                                <input type="text" placeholder="Phone number" />
                            </div>
                            <div className="form-group">
                                <input type="email" placeholder="Email address" />
                            </div>
                            <div className="form-group">
                                <textarea placeholder="Write your message"></textarea>
                            </div>
                            <button className="sendbtn" type="submit">Send Message</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default About;
