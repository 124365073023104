import React from 'react';
import './Breadcrumb.css';

const Breadcrumb = ({ pageTitle, breadcrumbs }) => {
    return (
        <div className="breadcrumb-container">
            <div className="breadcrumb-bg">
                <h1 className="page-title">{pageTitle}</h1>
                <nav className="breadcrumb-nav">
                    <ul className="breadcrumb-list">
                        {breadcrumbs.map((item, index) => (
                            <li key={index} className="breadcrumb-item">
                                {index < breadcrumbs.length - 1 ? (
                                    <>
                                        {/* Ensure no leading / in the link */}
                                        <a href={item.link}>{item.label}</a>
                                        <span className="breadcrumb-separator">{' > '}</span>
                                    </>
                                ) : (
                                    <span className="breadcrumb-current">{item.label}</span>
                                )}
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default Breadcrumb;
