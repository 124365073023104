import React from 'react';
import './PricingTable.css';

const PricingTable = () => {
    return (
        <div className="pricingTable">
            <h2 className="pricingTable-title">Find a plan that's right for you.</h2>
            <h3 className="pricingTable-subtitle">
               WE PROVIDE <br />BUSINESS PORTFOLIO
            </h3>

            <ul className="pricingTable-firstTable">
                <li className="pricingTable-firstTable_table">
                    <h1 className="pricingTable-firstTable_table__header">Bronze Package</h1>
                    <p className="pricingTable-firstTable_table__pricing">
                        <span>₹</span>
                        <span>12999</span>
                        <span>/Annual</span>
                    </p>
                    <ul className="pricingTable-firstTable_table__options">
                        <li>Basic SEO</li>
                        <li>Domain and Hosting</li>
                        <li>Responsive Design</li>
                        <li>Limited Design</li>
                    </ul>
                    <button className="pricingTable-firstTable_table__getstart">
                        Get Started Now
                    </button>
                </li>

                <li className="pricingTable-firstTable_table">
                    <h1 className="pricingTable-firstTable_table__header">Gold Package</h1>
                    <p className="pricingTable-firstTable_table__pricing">
                        <span>₹</span>
                        <span>17999</span>
                        <span>/Annual</span>
                    </p>
                    <ul className="pricingTable-firstTable_table__options">
                        <li>Custom Design</li>
                        <li>Advance SEO setup</li>
                        <li>Domain and Hosting</li>
                        <li>Basiv E-commerce setup</li>
                        <li>Support Service</li>
                    </ul>
                    <button className="pricingTable-firstTable_table__getstart">
                        Get Started Now
                    </button>
                </li>

                <li className="pricingTable-firstTable_table">
                    <h1 className="pricingTable-firstTable_table__header">
                        Platinum Package
                    </h1>
                    <p className="pricingTable-firstTable_table__pricing">
                        <span>₹</span>
                        <span>25999</span>
                        <span>/Annual</span>
                    </p>
                    <ul className="pricingTable-firstTable_table__options">
                        <li>Premium Custom Design</li>
                        <li>Comprehensive SEO Strattergy</li>
                        <li>Unlimited Page Designs</li>
                        <li>E-commerce Functionality</li>
                        <li>24/7 Support Service</li>
                    </ul>
                    <button className="pricingTable-firstTable_table__getstart">
                        Get Started Now
                    </button>
                </li>
            </ul>
        </div>
    );
};

export default PricingTable;
