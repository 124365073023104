import React from 'react';
import './ContactUs.css';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
const ContactUs = () => {
    const breadcrumbs = [
        { label: 'Home', link: 'home' },  // link without the leading slash
        { label: 'Contact Us', link: 'contact-us' },  // link without the leading slash
    ];
    return (
        <>
            <Breadcrumb pageTitle="Contact Us" breadcrumbs={breadcrumbs} />
        <div className="contact-container container">
            <div className="contact-info">
                <h2 className="contact-heading">Let's build an awesome project together</h2>
                <p className="contact-description">
                        We’re here to help bring your ideas to life. Whether you need a website, mobile app, or custom software solution, our team of experts is ready to collaborate and deliver exceptional results tailored to your needs.

                      </p>

                <div className="contact-details">
                    <div className="contact-item">
                        <div className="icon-box">
                            <span className="icon">&#128205;</span>
                        </div>
                        <div>
                            <h4>Address</h4>
                            <p>AIC Incubation center<br />NITTE, 574110</p>
                        </div>
                    </div>

                    <div className="contact-item">
                        <div className="icon-box">
                            <span className="icon">&#9993;</span>
                        </div>
                        <div>
                            <h4>Contact</h4>
                            <p>neuraltechitsolution@gmail.com<br />+91 9108316049</p>
                        </div>
                    </div>
                </div>

                <p className="office-hours">Office Hours: Mon – Sat: 9:30 AM – 5:30 PM</p>
            </div>

            <div className="contact-form">
                <h3>Fill The Contact Form</h3>
                <p>Feel free to contact with us, we don’t spam your email</p>

                <form>
                    <div className="form-group">
                        <input type="text" placeholder="Your name" />
                        <input type="text" placeholder="Phone number" />
                    </div>
                    <div className="form-group">
                        <input type="email" placeholder="Email address" />
                    </div>
                    <div className="form-group">
                        <textarea placeholder="Write your message"></textarea>
                    </div>
                    <button className='sendbtn' type="submit">Send Message</button>
                </form>
            </div>
        </div>
        </>
    );
};

export default ContactUs;
