import React from "react";
import "./Review.css"; // Import CSS for styling
import backgroundImage from '../../assets/images/cta-bg2.png';
import { motion } from 'framer-motion';
const Review = () => {
    const testimonials = [
        {
            name: "Leslie Alexander",
            title: "Freelance React Developer",
            feedback:
                "You made it so simple. My new site is so much faster and easier to work with than my old site. I just choose the page, make the change.",
            image: "https://picsum.photos/50?random=1", // Random image from Lorem Picsum
            rating: 5,
        },
        {
            name: "Jacob Jones",
            title: "Digital Marketer",
            feedback:
                "Simply the best. Better than all the rest. I’d recommend this product to beginners and advanced users.",
            image: "https://picsum.photos/50?random=2", // Random image from Lorem Picsum
            rating: 5,
        },
        {
            name: "Jenny Wilson",
            title: "Graphic Designer",
            feedback:
                "I cannot believe that I have got a brand new landing page after getting Omega. It was super easy to edit and publish.",
            image: "https://picsum.photos/50?random=3", // Random image from Lorem Picsum
            rating: 5,
        },
    ];

    return (
        <>
     
            <div className="hero-section">
                <motion.div
                    className="hero-content"
                    initial={{ opacity: 0, y: 100 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                    viewport={{ once: true }}
                >
                    <h2 className="hero-heading">Let's work together</h2>
                    <p className="hero-description">
                        Your partner for innovative app and web development, hardware solutions, and career opportunities.
                    </p>
                    <button className="hero-button">Let's Start a Project</button>
                </motion.div>
            </div>
            <div className="testimonials-container">
                <h3 className="testimonials-header">Our happy clients say about us</h3>
                <p className="testimonials-subtext">
                    {/* 2,157 people have said how good Rareblocks */}
                </p>
                <div className="testimonials-cards">
                    {testimonials.map((testimonial, index) => (
                        <div className="testimonial-card" key={index}>
                            <div className="testimonial-rating">
                                {"★".repeat(testimonial.rating)}
                            </div>
                            <p className="testimonial-feedback">"{testimonial.feedback}"</p>
                            <div className="testimonial-user">
                                <img
                                    src={testimonial.image}
                                    alt={testimonial.name}
                                    className="testimonial-image"
                                />
                                <div>
                                    <p className="testimonial-name">{testimonial.name}</p>
                                    <p className="testimonial-title">{testimonial.title}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <p className="testimonials-footer">Check all</p>
            </div>
    </>
    );
};

export default Review;
