import React from 'react';
import './Footer.css';
import { FaFacebookF, FaLinkedinIn, FaTwitter, FaInstagram, FaMapMarkerAlt } from 'react-icons/fa';
import logo from '../../assets/images/logopng.png';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-logo-section">
                    <h2 className="footer-logo">
                        <a className="navbar-brand" href="#">
                            <img src={logo} alt="Company Logo" />
                        </a>
                    </h2>
                    <p className="footer-description">
                        Empowering Your Business with the Most Advanced IT Solutions for a Brighter Future.
                        </p>
                    <div className="social-iconsfoot">
                        <a href="#" className="social-iconfoot"><FaFacebookF /></a>
                        <a href="#" className="social-iconfoot"><FaLinkedinIn /></a>
                        <a href="#" className="social-iconfoot"><FaTwitter /></a>
                        <a href="#" className="social-iconfoot"><FaInstagram /></a>
                    </div>
                </div>

                <div className="footer-links-section">
                    <div className="footer-column">
                        <h4>Quick Links</h4>
                        <ul>
                            <li><a href="#">About Us</a></li>
                            <li><a href="#">Our Team</a></li>
                            <li><a href="#">Pricing</a></li>
                            <li><a href="#">Blogs</a></li>
                            <li><a href="contactus">Contact Us</a></li>
                        </ul>
                    </div>

                    <div className="footer-column">
                        <h4>Services</h4>
                        <ul>
                            <li><a href="#">UI/UX Design</a></li>
                            <li><a href="#">App Development</a></li>
                            <li><a href="#">Digital Marketing</a></li>
                            <li><a href="#">Web Development</a></li>
                            <li><a href="#">Cyber Security</a></li>
                        </ul>
                    </div>

                    <div className="footer-column">
                        <h4>Our Branches</h4>
                        <ul>
                            <li><a href="#"><FaMapMarkerAlt /> AIC Incubation center, 3rd Floor, <br /> <b>NITTE</b></a></li>
                            <li><a href="#"><FaMapMarkerAlt /> Near Spandan Hospital, <br /><b>KARKALA</b></a></li>
                           
                        </ul>
                    </div>
                </div>
            </div>

            <div className="footer-bottom">
                <p>Copyright &copy; Neuraltech IT Solutions. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
