import React, { useState, useEffect } from 'react';
import './Navbar.css'; // Custom styles if needed
import logo from '../../assets/images/logopng.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhone, faEnvelope, faBars } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isSticky, setIsSticky] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    // Handle scroll event to toggle sticky state
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
       
        <div className={`navbarcont ${isSticky ? 'sticky' : ''} ${isSticky ? '' : 'container'}`}>
       
            <nav className="navbar navbar-expand-md">
                <div className="container">
                    <a className="navbar-brand" href="/">
                        <img src={logo} alt="Company Logo" />
                    </a>
                    <button
                        className="navbar-toggler"
                        type="button"
                        onClick={toggleMenu}
                        aria-controls="navbarNav"
                        aria-expanded={isMenuOpen}
                        aria-label="Toggle navigation"
                    >
                        <FontAwesomeIcon icon={faBars} />
                    </button>
                    <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarNav">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item"><a className="nav-link" href="/">Home</a></li>
                            <li className="nav-item"><a className="nav-link" href="/aboutus">About</a></li>
                            <li className="nav-item"><a className="nav-link" href="/service">Service</a></li>
                            <li className="nav-item"><a className="nav-link" href="#">Pages</a></li>
                            <li className="nav-item"><a className="nav-link" href="#">Blog</a></li>
                            <li className="nav-item"><a className="nav-link" href="contactus">Contact</a></li>
                                <li className="nav-item"><a className="btn btn-primary ml-md-3" href="contactus">Get a Support</a></li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
        </>
    );
};

export default Navbar;
